<template>
  <div class="player-info box">
    <div class="common-top">
      <span>已添加选手</span>
      <el-alert
        title="请填写实名信息"
        type="warning"
        :closable="false"
        show-icon>
      </el-alert>
      <span @click="handleAdd">添加选手</span>
    </div>
    <ul v-if="model.playerTableData">
      <li v-for="(item, index) in model.playerTableData" :key="index">
        <div class="name">{{ item.name }}</div>
        <div>{{ item.sex | filterSex}}</div>
        <div class="mobile">{{ item.mobile || ''}}</div>
        <div>
          {{ item.role == 1?'队员':'队长' }}
        </div>
        <img src="../../../assets/img/mobile-sign-up-management/delete.png" @click="handleDel(index)"/>
        <img src="../../../assets/img/mobile-personal-center/info.png" style="width:13px;margin-left:4px;"
             @click="handleEdit(index)"/>

      </li>
    </ul>

    <div class="add-info" v-if="showModel">
      <div class="title">添加选手信息</div>
      <el-form ref="playerInfo"
               :model="playerModel"
               :rules="playerRules"
               label-width="90px">
        <el-form-item label="姓名：" prop="name">
          <el-input
            v-model="playerModel.name"
            placeholder="请输入姓名"
            maxlength="20"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="性别：" prop="sex">
          <el-select placeholder="请选择性别" v-model="playerModel.sex" clearable>
            <el-option v-for="(item, index) in genderOptions" :key="index" :label="item.label" :value="item.value"/>
          </el-select>
        </el-form-item>
        <el-form-item label="是否队长" prop="role">
          <el-radio-group v-model="playerModel.role">
            <el-radio :label=2>是</el-radio>
            <el-radio :label=1>否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="手机号：" prop="mobile">
          <el-input v-model.trim="playerModel.mobile" maxlength="11" placeholder="请输入手机号" clearable></el-input>
        </el-form-item>
        <el-form-item label="身份证" prop="idNumber">
          <el-input v-model.trim="playerModel.idNumber" maxlength="18" placeholder="请输入身份证" clearable></el-input>
        </el-form-item>
        <el-form-item label="邮箱：" prop="email">
          <el-input v-model.trim="playerModel.email" placeholder="请输入邮箱" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="save-btn" @click="handleSave">确定</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
  import {isMobile, isIdCard, isEmail, isName} from "@/utils/validate"
  import genderOptions from "@/const/genderOptions"

  export default {
    name: '',
    props: {
      model: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    data() {
      const validateMobile = (rule, value, callback) => {
        if (!value) {
          callback(new Error('请输入手机号'))
        } else if (!isMobile(value)) {
          callback(new Error('请输入正确的手机号'))
        } else {
          callback()
        }
      }
      const validateIdCard = (rule, value, callback) => {
        if (!value) {
          callback(new Error('请输入身份证号码'))
        } else if (!isIdCard(value)) {
          callback(new Error('请输入正确的身份证号码'))
        } else {
          callback()
        }
      }
      const validateEmail = (rule, value, callback) => {
        if (!value) {
          callback(new Error('请输入邮箱'))
        } else if (!isEmail(value)) {
          callback(new Error('请输入正确的邮箱'))
        } else {
          callback()
        }
      }
      const validateName = (rule, value, callback) => {
        if (!value) {
          callback(new Error('请输入姓名'))
        } else if (!isName(value)) {
          callback(new Error('请输入正确的姓名'))
        } else {
          callback()
        }
      }
      return {
        genderOptions,
        playerModel: {
          name: '',
          sex: 0,
          mobile: '',
          role: 1,
          idNumber: '',
          email: ''
        },
        playerRules: {
          name: [{required: true, validator:validateName, trigger: "blur"}],
          sex: [
            {required: true, message: "请选择性别", trigger: "change"}
          ],
          mobile: [
            {required: true, validator: validateMobile, trigger: "blur"}
          ],
          idNumber: [
            {required: true, validator: validateIdCard, trigger: "blur"}
          ],
          email: [
            {required: true, validator: validateEmail, trigger: "blur"}
          ]
        },
        showModel: false,
        editIndex: 0,
        editFlag: false,
        maxPlayers:window._CONFIG['MAX_PLAYER_NUMS'] || 2
      }
    },
    filters: {
      filterSex(val) {
        switch (val) {
          case 0:
            return '保密'
          case 1:
            return '男'
          case 2:
            return '女'
          default:
            return '未知'
        }
      }
    },
    components: {},
    methods: {
      handleDel(index) {
        this.$confirm("确认删除？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          // console.log("index is", index);
          this.$emit('on-delete', false, index);
        })
        // this.$emit('on-delete', false, index);
      },
      handleEdit(index) {
        this.editIndex = index;
        let obj = this.model.playerTableData[index];
        this.playerModel = {...obj};
        this.editFlag = true;
        this.showModel = true;
      },
      handleAdd() {
        if (this.model.playerTableData.length == this.maxPlayers) {
          this.$message.warning(`最多添加${this.maxPlayers}位选手`);
          return;
        }
        this.showModel = true;
        this.editFlag = false;// 新增
      },
      handleSave() {
        this.$refs['playerInfo'].validate((valid) => {
          if (!valid) return;
          if(!this.editFlag){
            this.$emit('on-add', false, this.playerModel);
          }else{
            this.$emit('on-edit', false, this.playerModel, this.editIndex)
          }
          this.showModel = false;
          this.initModel();
        })
      },
      initModel() {
        this.playerModel = {
          name: '',
          sex: 0,
          mobile: '',
          idNumber: '',
          email: '',
          role: 1
        }
      }
    }
  }
</script>

<style scoped lang="scss">

</style>