<template>
  <div class="teacher-info box">
    <div class="common-top">
      <span>已添加老师</span>
      <el-alert
        title="请填写实名信息"
        type="warning"
        :closable="false"
        show-icon>
      </el-alert>
      <span @click="handleAdd">添加老师</span>
    </div>
    <ul v-if="model.teacherTableData">
      <li v-for="(item, index) in model.teacherTableData" :key="index">
        <div class="name">{{ item.name }}</div>
        <div>{{ item.sex | filterSex}}</div>
        <div class="mobile">{{ item.mobile || ''}}</div>
        <img src="../../../assets/img/mobile-sign-up-management/delete.png" @click="handleDel(index)"/>
        <img src="../../../assets/img/mobile-personal-center/info.png" style="width:13px;margin-left:4px;"
             @click="handleEdit(index)"/>
      </li>
    </ul>

    <div class="add-info" v-if="showModel">
      <div class="title">添加老师信息</div>
      <el-form ref="teacherInfo"
               :model="teacherModel"
               :rules="teacherRules"
               label-width="90px">
        <el-form-item label="姓名：" prop="name">
          <el-input
            v-model="teacherModel.name"
            placeholder="请输入姓名"
            maxlength="20"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="性别：" prop="sex">
          <el-select placeholder="请选择性别" v-model="teacherModel.sex" clearable>
            <el-option v-for="(item, index) in genderOptions" :key="index" :label="item.label" :value="item.value"/>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号：" prop="mobile">
          <el-input v-model.trim="teacherModel.mobile" maxlength="11" placeholder="请输入手机号" clearable></el-input>
        </el-form-item>
        <el-form-item label="身份证" prop="idNumber">
          <el-input v-model.trim="teacherModel.idNumber" maxlength="18" placeholder="请输入身份证" clearable></el-input>
        </el-form-item>
        <el-form-item label="邮箱：" prop="email">
          <el-input v-model.trim="teacherModel.email" placeholder="请输入邮箱" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="save-btn" @click="handleSave">确定</el-button>
        </el-form-item>
      </el-form>
    </div>


  </div>
</template>

<script>
  import {isMobile, isIdCard, isEmail, isName} from "@/utils/validate"
  import genderOptions from "@/const/genderOptions"

  export default {
    name: '',
    props: {
      model: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    data() {
      const validateMobile = (rule, value, callback) => {
        if (!value) {
          callback(new Error('请输入手机号'))
        } else if (!isMobile(value)) {
          callback(new Error('请输入正确的手机号'))
        } else {
          callback()
        }
      }
      const validateIdCard = (rule, value, callback) => {
        if (!value) {
          callback(new Error('请输入身份证号码'))
        } else if (!isIdCard(value)) {
          callback(new Error('请输入正确的身份证号码'))
        } else {
          callback()
        }
      }
      const validateEmail = (rule, value, callback) => {
        if (!value) {
          callback(new Error('请输入邮箱'))
        } else if (!isEmail(value)) {
          callback(new Error('请输入正确的邮箱'))
        } else {
          callback()
        }
      }
      const validateName = (rule, value, callback) => {
        if (!value) {
          callback(new Error('请输入姓名'))
        } else if (!isName(value)) {
          callback(new Error('请输入正确的姓名'))
        } else {
          callback()
        }
      }
      return {
        genderOptions,
        teacherModel: {
          name: '',
          sex: 0,
          mobile: '',
          idNumber: '',
          email: '',
          role: 0
        },
        teacherRules: {
          name: [{required: true, validator:validateName, trigger: "blur"}],
          sex: [
            {required: true, message: "请选择性别", trigger: "change"}
          ],
          mobile: [
            {required: true, validator: validateMobile, trigger: "blur"}
          ],
          idNumber: [
            {required: true, validator: validateIdCard, trigger: "blur"}
          ],
          email: [
            {required: true, validator: validateEmail, trigger: "blur"}
          ]
        },
        showModel: false,
        editIndex: 0,
        editFlag: false,
        maxTeachers:window._CONFIG['MAX_TEACHER_NUMS'] || 2
      }
    },
    filters: {
      filterSex(val) {
        switch (val) {
          case 0:
            return '保密'
          case 1:
            return '男'
          case 2:
            return '女'
          default:
            return '未知'
        }
      }
    },
    components: {},
    methods: {
      handleDel(index) {
        this.$confirm("确认删除？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          this.$emit('on-delete', true, index);
        })
      },
      handleEdit(index) {
        this.editIndex = index;
        let obj = this.model.teacherTableData[index];
        this.teacherModel = {...obj};
        this.editFlag = true;
        this.showModel = true;
      },
      handleAdd() {
        if (this.model.teacherTableData.length == this.maxTeachers) {
          this.$message.warning(`最多添加${this.maxTeachers}位指导老师`);
          return;
        }
        this.showModel = true;
        this.editFlag = false;// 新增
      },
      handleSave() {
        this.$refs['teacherInfo'].validate((valid) => {
          if (!valid) return;
          if (!this.editFlag) {
            this.$emit('on-add', true, this.teacherModel);
          } else {
            this.$emit('on-edit', true, this.teacherModel, this.editIndex)
          }
          this.showModel = false;
          this.initModel();
        })
      },
      initModel() {
        this.teacherModel = {
          name: '',
          sex: 0,
          mobile: '',
          idNumber: '',
          email: '',
          role: 0
        }
      }
    }
  }
</script>

<style scoped lang="scss">

</style>