<template>
  <div class="mobile-sign-up-management">
    <div class="sign-up-all">
      <div class="all-top">
        <img src="../../assets/img/mobile-personal-center/back.png" @click="handleBack"/>
        <span class="sign-info">报名信息</span>
        <span class="view-info" @click="handleSignUp">查看报名信息</span>
      </div>
      <ul class="common-ul">
        <li v-for="(item, index) in tabList"
            :style="{backgroundImage:activeIndex==index?`url(${item.activeBg})`:`url(${item.bg})`}"
            :key="index">{{item.title}}
        </li>
      </ul>

      <component ref="commonRef"
                 :is="tabList[activeIndex] && tabList[activeIndex].component"
                 :model="model"
                 @on-add="handleAdd"
                 @on-edit="handleEdit"
                 @on-delete="handleDel"/>

      <div class="bottom-btn">
        <el-button v-if="activeIndex == 1 || activeIndex ==2" @click="handlePrevious">上一步</el-button>
        <el-button @click="handleNext" v-loading=loading>{{activeIndex == 0 || activeIndex ==1? '下一步': '提交'}}
        </el-button>
      </div>
    </div>

    <join-desc-dialog v-model="showJoinDesc" @on-agree="realSubmit"/>
  </div>
</template>

<script>
  import BasicInfo from './components/basic-info'
  import TeacherInfo from './components/teacher-info'
  import PlayerInfo from './components/player-info'
  import {signUp, getOrgNumByName} from "@/api/sign-up-management"
  import JoinDescDialog from '@/views/sign-up-management/components/join-desc-dialog'

  export default {
    name: '',
    data() {
      return {
        tabList: [
          {
            title: '基础信息',
            component: 'basic-info',
            bg: require('../../assets/img/mobile-sign-up-management/basic-info.png'),
            activeBg: require('../../assets/img/mobile-sign-up-management/basic-info-active.png')
          },
          {
            title: '指导老师',
            component: 'teacher-info',
            bg: require('../../assets/img/mobile-sign-up-management/teacher-info.png'),
            activeBg: require('../../assets/img/mobile-sign-up-management/teacher-info-active.png')
          },
          {
            title: '选手',
            component: 'player-info',
            bg: require('../../assets/img/mobile-sign-up-management/player-info.png'),
            activeBg: require('../../assets/img/mobile-sign-up-management/player-info-active.png')
          }
        ],
        activeIndex: 0,
        model: {
          groupName: '',
          province: '',
          orgName: '',
          college: '',
          major: '',
          teacherTableData: [],
          playerTableData: [],
        },
        loading: false,
        showJoinDesc: false,
        maxSignUps: window._CONFIG['MAX_SIGN_UP_NUMS'] || 10,
        maxJoins: window._CONFIG['MAX_JOIN_NUMS'] || 5,
      }
    },
    components: {
      BasicInfo,
      TeacherInfo,
      PlayerInfo,
      JoinDescDialog,
    },
    methods: {
      handleBack() {
        this.$router.go(-1)
      },
      handleSignUp() {
        this.$router.push({name: 'mobile-personal-center'})
      },
      handleDel(flag, index) {
        if (flag) {
          this.model.teacherTableData.splice(index, 1)
        } else {
          this.model.playerTableData.splice(index, 1)
        }
      },
      handleAdd(flag, obj) {
        if (flag) {
          this.model.teacherTableData.push(obj);
        } else {
          this.model.playerTableData.push(obj);
        }
      },
      handleEdit(flag, obj, editIndex) {
        if (flag) {
          this.$set(this.model.teacherTableData, editIndex, obj);
        } else {
          this.$set(this.model.playerTableData, editIndex, obj);
        }
      },
      handlePrevious() {
        this.activeIndex--;
      },
      async handleNext() {
        if (this.activeIndex == 0) {
          let flag;
          try {
            flag = await this.$refs['commonRef'].validate()
          } catch {
            flag = false;
          }

          if (!flag) return;

          let {orgName = ''} = this.model;
          let {maxSignUps, maxJoins} = this;

          let res = await getOrgNumByName({orgName});
          if (!res || !res.data) return;
          if (res.data.code != 0) return;

          if (Number(res.data.data) >= this.maxSignUps) {
            this.$message.error({
              message: `1、每个学校仅限${maxSignUps}支队伍报名、${maxJoins}支队伍参加比赛，当前学校报名团队已超过最大数量，请联系指导教师确认报名参赛资格。<br/>2、如果您是指导老师，请及时联系对接的市场销售人员，并在校内商定选拔出不大于${maxJoins}支的参赛队伍。`,
              duration: 0,
              showClose: true,
              dangerouslyUseHTMLString:true,
            })
            return;
          }

          this.$message.success(`每个学校仅限${maxSignUps}支队伍报名,请知悉`)
          this.activeIndex++;
          return;
        }

        if (this.activeIndex == 1) {
          let {teacherTableData = []} = this.model;
          if (teacherTableData.length < 1) {
            this.$message.error('请至少添加1位指导老师');
            return false;
          }
          this.activeIndex++
          return;
        }

        if (this.activeIndex == 2) {
          let {playerTableData = []} = this.model;

          if (playerTableData.length != 2) {
            this.$message.error('请添加2位选手');
            return false;
          }

          if (playerTableData.every(val => val.role == 1)) {
            this.$message.error("请选择其中一名选手为'队长'");
            return false;
          }

          this.showJoinDesc = true;
        }
      },

      async realSubmit() {
        let {teacherTableData = [], playerTableData = []} = this.model;
        let detailDTOList = [...teacherTableData, ...playerTableData]
        let params = Object.assign({}, this.model, {detailDTOList})

        this.loading = true;
        try {
          let res = await signUp(params);
          this.loading = false;
          if (!res || !res.data) return;
          if (res.data.code != '0') return;
          this.$message.success('报名成功');
          // this.$emit('on-success')
          this.$router.replace({name: 'mobile-bridge'})
        } catch (e) {
          this.loading = false;
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .mobile-sign-up-management {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: url("../../assets/img/home/mobile-bg.jpg") no-repeat center;
    background-size: cover;
    position: relative;
    color: #333;
    font-size: 14px;

    .sign-up-all {
      width: 90%;
      height: 76vh;
      margin: 5vh auto;
      border-radius: 10px;
      opacity: 1;
      background: rgba(255, 255, 255, 0.8);
      box-shadow: 0px 4px 10px 0px rgba(0, 33, 61, 0.3);
      padding: 20px 10px;
      display: flex;
      flex-direction: column;
      /*border: 1px solid red;*/

      .all-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #C8DAE8;
        padding-bottom: 10px;
        .sign-info {
          font-size: 18px;
          font-weight: 500;
        }
        .view-info {
          color: #0084FF;
          font-size: 12px;
        }
      }

      ul {
        display: flex;
        list-style: none;
        margin-top: 10px;
        li {
          flex: 1;
          height: 28px;
          text-align: center;
          line-height: 28px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
        }
      }

      .box {
        padding-top: 10px;
        flex: 1;
        height: 1%;
        display: flex;
        flex-direction: column;
        /deep/ .title {
          position: relative;
          font-size: 14px;
          color: #104175;
          font-weight: bold;
          &::before {
            position: absolute;
            top: 4px;
            left: -10px;
            content: '';
            width: 2px;
            height: 12px;
            background: #104175;
          }
        }
        /deep/ .common-top {
          width: 100%;
          height: 28px;
          background: linear-gradient(180deg, #E4F1FF 0%, #FFFFFF 100%);
          padding: 0 10px 0 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          span {
            &:first-child {
              color: #444444
            }
            &:last-child {
              color: #0084FF
            }
          }
          .el-alert {
            width: 135px;
            font-size: 10px;
            padding: 4px;
          }
        }

        /deep/ ul {
          list-style: none;
          li {
            height: 40px;
            background: #FFFFFF;
            display: flex;
            align-items: center;
            padding: 0 20px;
            div {
              margin-right: 5px;
            }
            .name {
              flex: 2;
              margin-right: 0;
            }
            .mobile {
              flex: 3;
              margin-right: 0;
            }

          }
        }

        /deep/ .add-info {
          padding: 10px;
          flex: 1;
          /*height: 1%;*/
          overflow-y: auto;
        }

        /deep/ .el-input {
          height: 32px !important;
          border-radius: 18px;
        }
        /deep/ .el-input__inner {
          height: 32px !important;
          border-radius: 18px;
          line-height: 32px !important;
        }

        /deep/ .el-input__icon {
          line-height: 32px !important;
        }
        /deep/ .el-select {
          width: 100%;
        }
        /deep/ .save-btn {
          min-width: 100% !important;
          height: 36px;
          border-radius: 20px;
          background: #EEF5FF;
          border: 1px solid #0084FF;
          box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
          color: #0084FF;
        }
        /deep/ .el-form-item {
          margin-bottom: 12px;
        }
        /deep/ .el-form-item__error {
          padding-top: 0;
        }
      }

      .bottom-btn {
        width: 100%;
        /*height: 90px;*/
        padding-top: 10px;
        display: flex;
        /*border: 1px solid red;*/
        align-items: center;
        justify-content: center;
        border-top: 1px solid #C8DAE8;
        /deep/ .el-button {
          flex: 1;
          &:first-child {
            height: 36px;
            border-radius: 20px;
            opacity: 1;
            background: #FFFFFF;
            box-sizing: border-box;
            border: 1px solid #999999;
            color: #6A6A6A;
          }
          &:last-child {
            height: 36px;
            border-radius: 20px;
            opacity: 1;
            background: linear-gradient(270deg, #05C0FF 0%, #005EFF 100%);
            color: #FFFFFF;
          }
        }
      }
    }

  }
</style>