<template>
  <div class="basic-info box">
    <div class="title">信息</div>
    <el-form ref="basicInfo"
             :model="model"
             :rules="rules"
             label-width="90px">
      <el-form-item label="团队名称：" prop="groupName">
        <el-input
          v-model.trim="model.groupName"
          placeholder="请输入团队名称"
          maxlength="20"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="省份：" prop="province">
        <el-select placeholder="请输入省份" v-model="model.province" clearable>
          <el-option v-for="(item, index) in provinceOptions" :key="index" :label="item.label" :value="item.value"/>
        </el-select>
      </el-form-item>
      <el-form-item label="学校名称：" prop="orgName">
        <el-tooltip class="item" effect="dark" content="请务必填写学校全称" placement="bottom-start">
          <el-autocomplete
            v-model.trim="model.orgName"
            placeholder="请输入学校全称"
            :fetch-suggestions="querySearch"
            clearable
          ></el-autocomplete>
        </el-tooltip>
      </el-form-item>
      <el-form-item label="学院/系：" prop="college">
        <el-input
          v-model.trim="model.college"
          placeholder="请输入学院/系"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="专业名称：" prop="major">
        <el-input
          v-model.trim="model.major"
          placeholder="请输入专业名称"
          clearable
        ></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  import genderOptions from "@/const/genderOptions"
  import {dictByType} from "@/api/sign-up-management"
  import schoolData, {allData} from '@/const/school';

  export default {
    name: '',
    props: {
      model: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    data() {
      return {
        genderOptions,
        schoolData,
        allData,
        rules: {
          // avatar: [{required: true, message: "请上传团队头像"}],
          groupName: [{required: true, message: "请输入团队名称", trigger: "blur"}],
          province: [
            {required: true, message: "请输入省份", trigger: "change"}
          ],
          orgName: [
            {required: true, message: "请输入学校名称", trigger: "blur"}
          ],
          college: [
            {required: true, message: "请输入学院/系", trigger: "blur"}
          ],
          major: [
            {required: true, message: "请输入专业名称", trigger: "blur"}
          ],
        },
        provinceOptions: [],
      }
    },
    created() {
      this.dictByType();
    },
    components: {},
    methods: {
      querySearch(queryString, cb) {
        let list = [];
        if (this.model.province) {
          let obj = this.schoolData.find(val => {
            return val.province_code == this.model.province;
          })
          if (obj) {
            list = obj.all_universities;
          }
        } else {
          list = this.allData
        }
        let results = queryString ? list.filter(this.createFilter(queryString)) : list;
        // 调用 callback 返回建议列表的数据
        cb(results);
      },
      createFilter(queryString) {
        return (val) => {
          return (val.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },
      async dictByType() {
        let res = await dictByType('geo_province_division');
        if (!res || !res.data) return;
        if (res.data.code != 0) return;
        this.provinceOptions = Object.assign([], res.data.data);
      },
      async validate() {
        return await this.$refs['basicInfo'].validate();
      }
    }
  }
</script>

<style scoped lang="scss">
  .basic-info {
    width: 100%;
    /*border:1px solid red;*/
    padding: 10px;
    /deep/ .el-input {
      height: 32px !important;
      border-radius: 18px;
    }
    /deep/ .el-input__inner {
      height: 32px !important;
      border-radius: 18px;
      line-height: 32px !important;
    }

    /deep/ .el-input__icon {
      line-height: 32px !important;
    }
    /deep/ .el-select {
      width: 100%;
    }
    /deep/ .el-autocomplete {
      width: 100%;
    }

    .bottom {
      text-align: center;
    }
    /deep/ .el-button {
      min-width: 90%;
      height: 36px;
      border-radius: 20px;
      opacity: 1;
      background: linear-gradient(270deg, #05C0FF 0%, #005EFF 100%);
      color: #fff;
    }

  }
</style>